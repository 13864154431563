import './App.css';
import { ButtonA } from './ButtonA';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useEffect, useState } from 'react';

const Preview = (props) => {
    const [txtData, setTxtData] = useState({ title: '', desc: '' })

    let temp_arry = [];
    let imgData = [];

    const isValidUrl = urlString => {
        try {
            return Boolean(new URL(urlString));
        }
        catch (e) {
            return false;
        }
    }

    for (let i = 0; i < props.platform.current.length; i++) {
        if (props.platform.current[i] != null && isValidUrl(props.platform.current[i].value)) {
            temp_arry[i] = {}
            temp_arry[i].name = props.platform.current[i].id
            temp_arry[i].url = props.platform.current[i].value
        }
    }

    for (let i = 0; i < props.images.current.files.length; i++) {
        imgData[i] = {}
        imgData[i].img = URL.createObjectURL(props.images.current.files[i])
    }

    useEffect(() => {
        setTxtData({ title: props.title.current.value, desc: props.desc.current.value })
    }, [props]);


    return (
        <div className="App">
            <Carousel autoPlay showThumbs={false} showStatus={false} showArrows={false}>
                {imgData.map((data, id) => {
                    if (data.img !== '') {
                        return (
                            <div key={id}>
                                <img src={data.img} alt="img" />
                            </div>
                        )
                    }
                    else {
                        return false
                    }
                })}
            </Carousel>

            <div className="btn-container">
                <h3 className='title-txt'>{txtData.title}</h3>
                <p className='desc-txt'>{txtData.desc}</p>
                {temp_arry.map((data, id) => {
                    if (data.url !== '' && data.url !== undefined) {
                        return <ButtonA linkName={data.name.toUpperCase()} url={data.url} key={id} />
                    } else {
                        return false
                    }
                })}
            </div>
        </div>
    );
}

export default Preview;