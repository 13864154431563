import './App.css';
import { Buttons } from './Buttons';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'

const API_URL = "https://www.newsfeedsmartapp.com/link_crator/api/get_links.php"
const IMG_BASE_URL = "https://www.newsfeedsmartapp.com/link_crator/uploads/"

function Home() {
    const paramas = useParams()
    const app_id = paramas.userId
    const [txtData, setTxtData] = useState({ title: '', desc: '' })
    const [imgData, setImgData] = useState([])

    const [items, setItems] = useState([
        {
            "name": "FLIPKART",
            "url": ""
        },
        {
            "name": "AMAZON",
            "url": ""
        },
        {
            "name": "NYKAA",
            "url": ""
        },
        {
            "name": "MYNTRA",
            "url": ""
        }
    ])

    useEffect(() => {
        const get_details = async () => {
            const data = new FormData()
            data.append('app_id', app_id)
            try {
                const res = await axios.post(API_URL, data);

                setTxtData({
                    title: res.data.title_txt,
                    desc: res.data.desc_txt
                })

                setItems([
                    {
                        "name": "FLIPKART",
                        "url": res.data.Flipkart
                    },
                    {
                        "name": "AMAZON",
                        "url": res.data.Amazon
                    },
                    {
                        "name": "NYKAA",
                        "url": res.data.Nykaa
                    },
                    {
                        "name": "MYNTRA",
                        "url": res.data.Myntra
                    }
                ])

                if (res.data.img_1!=='' && res.data.img_2!=='' && res.data.img_3!=='' && res.data.img_4!=='') {
                    console.log(4)
                    setImgData([
                        {
                            'img': res.data.img_1
                        },
                        {
                            'img': res.data.img_2
                        },
                        {
                            'img': res.data.img_3
                        },
                        {
                            'img': res.data.img_4
                        }
                    ])
                }
                
               else if (res.data.img_1!=='' && res.data.img_2!=='' && res.data.img_3!=='') {
                    console.log(3)
                    setImgData([
                        {
                            'img': res.data.img_1
                        },
                        {
                            'img': res.data.img_2
                        },
                        {
                            'img': res.data.img_3
                        }
                    ])
                } 
                
                else if (res.data.img_1!=='' && res.data.img_2!=='') {
                    console.log(2)
                    setImgData([
                        {
                            'img': res.data.img_1
                        },
                        {
                            'img': res.data.img_2
                        }
                    ])
                } 

                else if(res.data.img_1!==''){
                    console.log(1)
                    setImgData([
                        {
                            'img': res.data.img_1
                        }
                    ])
                } 

                

            } catch (error) {
                console.log(console.error(error));
            }
        }
        get_details();
    }, [app_id]);


    return (
        <div className="App">
            <Carousel autoPlay showThumbs={false} showStatus={false} showIndicators={false}>
                {imgData.map((data, id) => {
                    if (data.img!=='') {
                        return (
                            <div key={id}>
                                <img src={IMG_BASE_URL + data.img} alt="img" />
                            </div>
                        )
                    } else {
                        return false
                    }
                })}
            </Carousel>

            <div className="btn-container">
                <h3 className='title-txt'>{txtData.title}</h3>
                <p className='desc-txt'>{txtData.desc}</p>
                {items.map((data, id) => {
                    if (data.url !== '' && data.url !== undefined) {
                        return <Buttons linkName={data.name} url={data.url} key={id} />
                    } else {
                        return false
                    }
                })}
            </div>
        </div>
    );
}

export default Home;