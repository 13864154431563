import { Routes, Route } from 'react-router-dom'
import Upload from './Upload';
import Home from './Home';
import TemplateA from './TemplateA';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Upload />}></Route>
      <Route path='/links/:userId' element={<Home />}></Route>
      <Route path='/link/:userId' element={<TemplateA />}></Route>
    </Routes>
  );
}

export default App;