import React from 'react'
import './ButtonA.css'

export const ButtonA = (props) => {
    const curImg = 'https://www.newsfeedsmartapp.com/link_crator/assets/buttons/'+props.linkName+'.png';
    return (
        <>
            <a href={props.url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                <div className='link_button'>
                    <img src={curImg} alt={props.linkName}></img>
                </div>
            </a>
        </>
    )
}