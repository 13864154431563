import React from 'react'

export const Buttons = (props) => {
    const link_button = {
        color: "white",
        backgroundColor: "DodgerBlue",
        padding: "10px",
        fontFamily: "Arial",
        margin: "15px auto",
        cursor: "pointer",
    };
    return (
        <>
            <a href={props.url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                <div style={link_button}>{props.linkName}</div>
            </a>
        </>
    )
}
