import { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Form, Button, Navbar, Container, Nav, Alert } from 'react-bootstrap'
import axios from 'axios'
import './Upload.css'
import Preview from './Preview'
import Frame from 'react-frame-component'

const options = [
    { value: 'Amazon', label: 'AMAZON' },
    { value: 'Flipkart', label: 'FLIPKART' },
    { value: 'Myntra', label: 'MYNTRA' },
    { value: 'Nykaa', label: 'NYKAA' }
]

const API_URL = "https://www.newsfeedsmartapp.com/link_crator/api/api.php"

let platform_link = {};
for (let i = 0; i < options.length; i++) {
    platform_link[options[i].value] = '';
}

const Upload = () => {
    const [selectedOption, setSelectedPlatform] = useState([])
    const [validPlatform, setValidPlatform] = useState(0)
    const [formSucess, setFormSucess] = useState({ success: false, message: '', link: '' })
    const title_txt = useRef(null)
    const desc_txt = useRef(null)
    const platform = useRef([])
    const images = useRef(null)

    const [previewCount, setPreviewCount] = useState(0)

    useEffect(() => {

    }, [selectedOption, previewCount]);

    const isValidUrl = urlString => {
        try {
            return Boolean(new URL(urlString));
        }
        catch (e) {
            return false;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setPreviewCount(previewCount + 1)

        if (title_txt.current.value === '') {
            title_txt.current.classList.add("frm-error")
            return
        } else {
            title_txt.current.classList.remove("frm-error")
        }

        if (desc_txt.current.value === '') {
            desc_txt.current.classList.add("frm-error")
            return
        } else {
            desc_txt.current.classList.remove("frm-error")
        }

        if (selectedOption.length === 0) {
            setValidPlatform(1)
            return
        } else {
            setValidPlatform(0)
        }

        if (selectedOption.length > 0) {
            for (let i = 0; i < platform.current.length; i++) {
                if (platform.current[i] != null) {
                    if (platform.current[i].value === '' || !isValidUrl(platform.current[i].value)) {
                        platform.current[i].classList.add("frm-error")
                        return
                    } else {
                        platform.current[i].classList.remove("frm-error")
                    }
                }
            }
        }

        if (images.current.files.length === 0 || images.current.files.length > 4) {
            images.current.classList.add("frm-error")
            return
        } else {
            images.current.classList.remove("frm-error")
        }

        let valid_img = [];

        // for (let i = 0; i < images.current.files.length; i++) {
        //     let img = new Image()
        //     img.src = window.URL.createObjectURL(images.current.files[i])
        //     img.onload = () => {
        //         if (img.width === 1242 && img.height === 2208) {
        //             valid_img.push('correct')
        //             images.current.classList.remove("frm-error")
        //         } else {
        //             valid_img.push('incorrect')
        //             images.current.classList.add("frm-error")
        //             return
        //         }
        //     }
        // }
        
        if (valid_img.includes("incorrect")) {
            console.log('invalid')
            return
        }


        if (e.target.id === 'submit-btn') {
            console.log('aaa')
            const data = new FormData()
            data.append('title_txt', title_txt.current.value)
            data.append('desc_txt', desc_txt.current.value)

            for (let i = 0; i < platform.current.length; i++) {
                if (platform.current[i] != null) {
                    data.append(platform.current[i].id, platform.current[i].value)
                }
            }

            for (let i = 0; i < images.current.files.length; i++) {
                data.append(i, images.current.files[i])
            }

            try {
                const res = await axios.post(API_URL, data)
                if (res.data.status === 200) {
                    setFormSucess({
                        success: true,
                        message: res.data.message,
                        link: res.data.link
                    })

                    setTimeout(() => {
                        setFormSucess({
                            success: false,
                            message: '',
                            link: res.data.link
                        })
                    }, 3000);
                } else {
                    setFormSucess({
                        success: false,
                        message: res.data.message,
                        link: ''
                    })

                    setTimeout(() => {
                        setFormSucess({
                            success: false,
                            message: '',
                            link: ''
                        })
                    }, 3000);
                }
            } catch (error) {
                console.log(console.error(error))
            }
        }
    }

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="/">LINK GENERATOR</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div className="container">
                <div className="row">
                    <div className="col-8 mt-5">
                        {formSucess.success && formSucess.message !== '' && <Alert variant="success">{formSucess.message}</Alert>}
                        {!formSucess.success && formSucess.message !== '' && <Alert variant="danger">{formSucess.message}</Alert>}

                        <Form>
                            <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Title" ref={title_txt} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formDesc">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={4} placeholder="Description" ref={desc_txt} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formPlatform">
                                <Form.Label>Platform</Form.Label>
                                <Select isMulti options={options} onChange={setSelectedPlatform} className={validPlatform === 1 ? 'frm-error' : ''} />
                            </Form.Group>

                            {selectedOption.map((data, i) => {
                                return (
                                    <Form.Group className="mb-3" controlId={data.value} key={i}>
                                        <Form.Label>{data.value} URL</Form.Label>
                                        <Form.Control type="text" placeholder={data.value + " URL"} ref={(el) => (platform.current[i] = el)} />
                                    </Form.Group>
                                )
                            })}

                            <Form.Group className="mb-3" controlId="formFile">
                                <Form.Label>Images</Form.Label>
                                <Form.Control type="file" accept="image/*" multiple ref={images} />
                            </Form.Group>

                            {formSucess.link !== '' &&
                                <Alert variant="secondary" className='links'>
                                    <a href={formSucess.link} target="_blank" rel="noreferrer">{formSucess.link}</a>
                                    <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => navigator.clipboard.writeText(formSucess.link)}>COPY</button>
                                </Alert>
                            }

                            <Button id="submit-btn" variant="primary" type="submit" onClick={handleSubmit}>
                                Submit
                            </Button>

                            <Button id="preview-btn" className='ms-2' variant="primary" type="button" onClick={handleSubmit}>
                                Preview
                            </Button>
                        </Form>
                    </div>

                    <div className="col-4 mt-5">
                        <div className="preview">
                            <div className="inner">
                                <div className="preview-wrap" >
                                    <div className="iframe-wrapper">
                                        <Frame initialContent='<!DOCTYPE html><html><head><link href="https://link-generator.newsfeedsmartapp.com/static/css/preview.css" rel="stylesheet"></head><body><div></div></body></html>'>
                                            <Preview title={title_txt} desc={desc_txt} platform={platform} images={images} />
                                        </Frame>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Upload;